/* CSS for Event Table */

/* Year filter button styling */
.filter-buttons {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter-button {
    background-color: #fb1263;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .filter-button:hover {
    background-color: #ef0a0a;
  }
  
  .filter-button.active {
    background-color: #333;
  }

/* Container styling */
.event-table-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .event-table-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: 650;
    color: #295bb1;
  }
  
  /* Table styling */
  .event-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    border: 1px solid #ddd;
  }
  
  .event-table thead {
    background-color: #71767a;
    color: #fff;
  }
  
  .event-table th, 
  .event-table td {
    padding: 15px;
    border: 1px solid #ddd; /* Adds borders between columns and rows */
    font-size: 1.1rem;
  }
  
  /* Centering specific columns */
  .event-table th:nth-child(1), /* Sr. No. */
  .event-table th:nth-child(3), /* Date */
  .event-table th:nth-child(4), /* Viewing */
  .event-table td:nth-child(1),
  .event-table td:nth-child(3),
  .event-table td:nth-child(4) {
    text-align: center;
    vertical-align: middle; /* Ensures the content is vertically centered */
  }
  
  /* Left-aligning Event column */
  .event-table td:nth-child(2),
  .event-table th:nth-child(2) {
    text-align: left;
  }
  
  /* Row hover effect */
  .event-table tbody tr {
    transition: background-color 0.2s ease-in-out;
  }
  
  .event-table tbody tr:hover {
    background-color: #f0f0f0;
  }
  
  /* View button styling */
  .view-button {
    text-decoration: none;
    background-color: #fb1263;
    color: #fff;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 0.95rem;
    transition: background-color 0.2s ease;
    display: inline-block;
  }
  
  .view-button:hover {
    background-color: #ef0a0a;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .event-table-container {
      padding: 10px;
    }
  
    .event-table th, 
    .event-table td {
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .event-table-container h2 {
      font-size: 1.5rem;
    }
  
    .view-button {
      padding: 6px 12px;
      font-size: 0.85rem;
    }
  }
  
@media (max-width: 576px) {
  .filter-button{
    margin-left: 0px;
  }

  .event-table-container {
    padding: 5px;
  }

  .event-table {
    font-size: 0.8rem;
  }

  .view-button {
    padding: 6px 10px;
    font-size: 0.75rem;
  }
}