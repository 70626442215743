/* Honors.css */
.honors-container {
    position: relative;
    min-height: 100vh;
    background: white;
    padding: 1rem 2rem;
    overflow: hidden;
    color: #333; /* Dark text for better readability on white */
  }
  
  .floating-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .floating-circle {
    position: absolute;
    border-radius: 50%;
    filter: blur(80px);
    opacity: 0.1;
    animation: float 15s infinite ease-in-out;
  }
  
  .c1 { 
    width: 300px; 
    height: 300px;
    background: #fe486d;
    top: 20%;
    left: 10%;
  }
  
  .c2 { 
    width: 400px; 
    height: 400px;
    background: #ff7e5f;
    top: 50%;
    right: 15%;
  }
  
  .c3 { 
    width: 250px; 
    height: 250px;
    background: #feb47b;
    bottom: 10%;
    left: 40%;
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
  
  .main-header {
    text-align: center;
    margin-bottom: 4rem;
    position: relative;
  }
  
  .gradient-text {
    background: linear-gradient(45deg, #fe486d, #ff7e5f);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: block;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .deco-line {
    width: 200px;
    height: 4px;
    background: linear-gradient(90deg, transparent, #fe486d, transparent);
    margin: 1rem auto;
  }
  
  /* Director Section */
  .director-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    /* margin-bottom: 5rem; */
  }
  
  .profile-card {
    position: relative;
    padding: 1rem;
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .hexagon-wrapper {
    position: relative;
    width: 400px;
    /* height: 400px; */
    margin:  1rem auto;
  }
  
  .hexagon {
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    background: linear-gradient(45deg, #fe486d, #ff7e5f);
    padding: 6px;
  }
  
  .hexagon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  
  .name-tag {
    text-align: center;
    font-size: 1.5rem;
    background: linear-gradient(45deg, #fe486d, #ff7e5f);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: 0;
  }
  
  .achievement-cloud {
    display: grid;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .cloud-card {
    padding: 1.5rem;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .cloud-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .cloud-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: #fe486d;
  }
  
  /* Timeline */
  .timeline-wrapper {
    position: relative;
  }
  
  .timeline {
    position: relative;
    padding-left: 30px;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 0;
    height: 100%;
    width: 2px;
    background: linear-gradient(to bottom, #fe486d, #ff7e5f);
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 2rem;
  }
  
  .timeline-marker {
    position: absolute;
    left: -25px;
    top: 5px;
    width: 12px;
    height: 12px;
    background: #fe486d;
    border-radius: 50%;
    border: 2px solid white;
  }
  
  .timeline-content {
    background: white;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-left: 3px solid #fe486d;
  }
  
  /* Son's Section */
  .son-section {
    margin: 5rem 0;
  }
  
  .son-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .sparkle {
    width: 40px;
    height: 40px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fe486d"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>');
    opacity: 0.8;
  }
  
  .trophy-case {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    padding: 2rem;
  }
  
  .trophy-card {
    padding: 1rem;
    border-radius: 15px;
    text-align: center;
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .trophy-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .trophy-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .gold { border: 2px solid #FFD700; }
  .silver { border: 2px solid #C0C0C0; }
  .emerald { border: 2px solid #50C878; }
  
  /* Contribution Wall */
  .contribution-wall {
    margin: 3rem 0;
    padding: 2rem;
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .contribution-card {
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }
  
  .card-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .contribution-icon {
    font-size: 2rem;
    color: #fe486d;
  }
  
  .contribution-list {
    list-style: none;
    padding: 0;
  }
  
  .contribution-list li {
    padding: 1rem;
    margin-bottom: 1rem;
    background: #f9f9f9;
    border-radius: 8px;
    position: relative;
    padding-left: 2rem;
  }
  
  .contribution-list li::before {
    content: '✓';
    position: absolute;
    left: 0.5rem;
    color: #fe486d;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .director-section {
      grid-template-columns: 1fr;
    }
    
    .gradient-text {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 480px) {
    .honors-container {
      padding: 2rem 1rem;
    }
  
    .gradient-text {
      font-size: 1.6rem;
      text-align: center;
    }
  
    .deco-line {
      width: 150px;
      height: 3px;
    }
  
    /* Director Section */
    .hexagon-wrapper {
      width: 300px;
    }
  }
  