/* General Styles for Event Details Page */
.event-details-page {
    padding: 40px 20px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .event-details-page h1 {
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: 650;
    color: #295bb1;
  }
  
  p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  
  /* Media Gallery */
  .media-gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .media-item {
    width: 260px;
    height: 180px;
    object-fit: cover;
    border-radius: 12px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .media-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Video Container */
.video-container {
  position: relative;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 34, 34);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

  /* Carousel Overlay */
  .carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .carousel-overlay .carousel {
    width: 80%;
    /* margin-top: 100px; */
    max-width: 900px;
    height: auto;
  }
  
  .carousel-overlay img,
  .carousel-overlay video {
    margin-top: 100px;
    max-height: 450px;
    object-fit: cover;
    width: 100%;
  }
  
  .close-carousel-btn {
    position: absolute;
    top: 90px;
    right: 30px;
    background-color: #ff4c4c;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .close-carousel-btn:hover {
    background-color: #d43f3f;
  }
  
  /* Responsive Styling */
  @media (max-width: 1200px) {
    .media-item {
      width: 180px;
      height: 130px;
    }
  
    .carousel-overlay img,
    .carousel-overlay video {
      max-height: 500px;
    }
  }
  
  @media (max-width: 768px) {
    /* .media-item {
      width: 100%;
      height: auto;
    } */
    .event-details-page h1 {
      font-size: 1.75rem;
    }
  
    .media-gallery {
      gap: 15px;
    }
  
    .media-item {
      width: 150px;
      height: 100px;
    }
    
    p {
      font-size: 1rem;
    }
  
    .carousel-overlay img,
    .carousel-overlay video {
      max-height: 400px;
    }
  
    .close-carousel-btn {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  
  /* Small screens */
  @media (max-width: 480px) {
    .carousel-overlay .carousel {
      width: 90%;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    .media-item {
      width: 100%;
      height: auto;
    }
    .play-button {
      padding: 8px 16px;
      font-size: 12px;
    }
  }
  