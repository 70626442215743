.privacy-policy-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .privacy-heading {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .last-updated {
    text-align: center;
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h4 {
    font-size: 20px;
    margin-top: 8px;
  }

  .privacy-policy-container h5 {
    font-size: 18px;
    margin-top: 8px;
  }
  
  .privacy-policy-container p {
    line-height: 1.6;
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .privacy-policy-container ul {
    padding-left: 20px;
  }
  
  .privacy-policy-container ul, .privacy-policy-container li {
    margin-bottom: 6px;
  }
  
  strong {
    color: #222;
  }
  
  @media (max-width: 600px) {
    .privacy-policy-container {
      padding: 15px;
    }
    .privacy-heading {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }
  