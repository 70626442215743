/* src/App.css */
*{
  margin: 0%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

/* 1. General styling for the navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fe486d;
  color: #0a0a0a;
  padding: 10px 20px;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

.navbar.scrolled {
  background-color: #fe486d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar-logo .logo {
  height: 70px;
  border-radius: 50px;
  margin-right: 10px;
}

.navbar-logo .logo-text {
  font-size: 2.2rem;
  font-weight: 550;
  color: #fff;
}

.navbar-toggler{
  border: none !important;
  box-shadow: none !important;
  /* background: transparent; */
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.ms-auto .nav-link {
  /* color: #fff !important; */
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

/* .nav-link:hover {
  color: #f8f9fa !important;
} */

.ms-auto .dropdown-menu {
  font-size: 1.1rem;
  font-weight: 500;
  /* background-color: #fe486d; */
  border: none;
}

.ms-auto .dropdown-item {
  font-size: 1.1rem;
  font-weight: 500;
  color: #000000;
}

.ms-auto .dropdown-item:hover, .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: #716d6d;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (max-width: 991px) {
  .navbar-logo .logo{
    height: 100px;
  }
  .navbar-logo .logo-text {
    font-size: 2.5rem;
  }

  .navbar-collapse {
    background-color: #fe486d;
    padding: 1rem;
  }
}

@media (max-width: 575px) {
  .navbar-logo .logo {
    height: 40px;
  }

  .navbar-logo .logo-text {
    font-size: 1rem;
  }
}



/* 2. Hero Section Styles */
.hero-section {
  position: relative;
  width: 100%;
  height: 90vh;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
  overflow: hidden; /* Ensures that slides don't overflow the container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Smooth transition effect */
}

.hero-slide.active {
  opacity: 1;
}

.hero-content {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  z-index: 10; /* Ensure it is above other content */
}

.hero-content h1 {
  font-size: 30px;
  margin: 0;
}

.hero-content p {
  color: #fff;
  font-size: 1rem;
  margin: 1rem 0;
}

.cta-btn {
  padding: 10px 20px;
  background-color: #f91967;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s; /* Smooth hover transition */
}

.cta-btn:hover {
  background-color: #333;
}

/* Navigation buttons */
.carousel-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-nav button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.carousel-nav button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Add smooth transitions */
.carousel-nav button:focus {
  outline: none;
}

/* Responsive Styles */

/* For tablets and medium-sized screens */
@media (max-width: 1024px) {
  .hero-section h1 {
    font-size: 1.5rem; /* Smaller heading */
  }

  .hero-content p {
    font-size: 1.2rem; /* Adjust paragraph font size */
  }

  .cta-btn {
    padding: 8px 16px; /* Smaller button */
    font-size: 0.9rem; /* Slightly smaller font */
  }

  .carousel-nav button {
    font-size: 1.5rem; /* Smaller navigation buttons */
  }
}

/* For mobile screens */
@media (max-width: 768px) {
  .hero-section {
    height: 70vh; /* Reduced height for smaller screens */
  }

  .hero-section h1 {
    font-size: 1.25rem; /* Further reduce heading size */
  }

  .hero-content p {
    font-size: 1rem; /* Further reduce paragraph size */
  }

  .cta-btn {
    padding: 6px 12px; /* Adjust button size */
    font-size: 0.8rem; /* Smaller button text */
  }

  .carousel-nav button {
    font-size: 1.25rem; /* Further reduce navigation button size */
  }
}

/* For extra small devices (phones) */
@media (max-width: 480px) {
  .hero-section {
    height: 60vh; /* Further reduce height for small devices */
  }

  .hero-section h1 {
    font-size: 1rem; /* Smallest heading size */
  }

  .hero-content p {
    font-size: 0.875rem; /* Smallest paragraph size */
  }

  .cta-btn {
    padding: 5px 10px; /* Minimal padding for buttons */
    font-size: 0.75rem; /* Smallest button text */
  }

  .carousel-nav button {
    font-size: 1rem; /* Minimum navigation button size */
  }
}


/* 3. About Us Section Styles */
.about-us {
  padding: 40px;
  background-color: #eceef1;
}

.about-us-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: justify;
  max-width: 1200px;
  margin: 0 auto;
}

.text-content {
  flex: 1;
  padding-right: 2rem;
}

.image-content {
  flex: 1;
  display: flex;
  flex-direction: column; /* Stack images vertically */
  justify-content: center;
  gap: 1rem; /* Space between images */
}

.image-content img {
  max-width: 100%;
  /* padding: auto; */
  border-radius: 10px;
}

.about-us h2 {
  font-size: 2rem;
  font-weight: 550;
  color: #295bb1;
  margin-bottom: 1rem;
}

.about-us p {
  font-size: 18px;
  color: #0a0a0a;
  margin-bottom: 1rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-us {
    padding: 1rem;
  }

  .about-us-content {
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }

  .text-content {
    padding-right: 0;
    padding-bottom: 1rem;
  }

  .image-content {
    margin-top: 1rem;
  }

  .about-us h2 {
    font-size: 2rem;
  }

  .about-us p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .about-us h2 {
    font-size: 1.5rem;
  }

  .about-us p {
    font-size: 14px;
  }
}


/* Legacy Section General Styling */
.background-section {
  background-color: #f5f5f5;
  padding: 90px 0;
  position: relative;
}

.legacy-section {
  background-color: #f5f5f5;
  padding: 40px 0;
  margin: -60px auto;
  border-radius: 10px;
  width: 80%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.legacy-section h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 550;
  color: #295bb1;
  margin-bottom: 30px;
}

.legacy-stats {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap; /* Allow items to wrap */
}

.legacy-item {
  text-align: center;
  flex: 1;
  padding: 10px;
}

.legacy-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.legacy-item h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.legacy-item p {
  font-size: 1.2rem;
  color: #666;
}

/* Enhanced Responsive Design */

/* Medium Screens (Tablets and Small Laptops) */
@media (max-width: 1024px) {
  .legacy-section {
    width: 90%;
  }
  
  .legacy-stats {
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .legacy-item {
    width: 30%;
  }
}

/* Mobile View (Screens Smaller than 768px) */
@media (max-width: 768px) {
  .legacy-section {
    width: 95%;
  }

  .legacy-stats {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .legacy-item {
    flex: 1;
    margin: 0 5px;
    padding: 10px;
  }

  .legacy-icon {
    width: 50px;
    height: 50px;
  }

  .legacy-item h3 {
    font-size: 1.5rem;
  }

  .legacy-item p {
    font-size: 1rem;
  }
}

/* Mobile Small Screens (Screens Smaller than 480px) */
@media (max-width: 480px) {
  .legacy-section {
    padding: 20px 0;
  }
  .legacy-section h2{
    font-size: 1.5rem;
  }
  .legacy-stats {
    flex-direction: row;
    justify-content: space-between;
  }

  .legacy-item {
    flex: 1;
    margin: 0 5px;
    padding: 8px;
  }

  .legacy-icon {
    width: 45px;
    height: 45px;
  }

  .legacy-item h3 {
    font-size: 1.2rem;
  }

  .legacy-item p {
    font-size: 0.9rem;
  }
}


/* 5. Vision & Mission Section Style */
.vision-mission-section {
  background-color: #f0eeee;
  padding: 20px 40px;
  text-align: center;
}

.vision-mission-container {
  display: grid;
  gap: 40px;
  justify-content: center; /* Center align the cards */
  /* flex-wrap: wrap; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

/* Card styling */
.vision-mission-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 10px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* margin-bottom: 10px; */
}

.vision-mission-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.card-image {
  width: 24%;
  height: auto;
  margin-bottom: 8px;
}

.card-image img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: add border-radius to image */
}

/* Heading styling */
.card-content h2 {
  font-size: 2rem;
  color: #295bb1;
  margin-bottom: 10px;
}

.card-content p, .card-content li {
  font-size: 18px;
  color: #555;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* .card-content li {
  margin-bottom: 10px;
} */

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .vision-mission-card {
    width: 100%; /* Full width on smaller screens */
    max-width: 300px;
  }

  .card-content h2 {
    font-size: 25px;
  }

  .card-content p, .card-content li {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .vision-mission-card {
    width: 110%;
    max-width: 400px;
    margin-left: -5%;
  }

  .card-content h2 {
    font-size: 1.5rem;
  }

  .card-content p, .card-content li {
    font-size: 14px;
  }
}


/* 6. Director's Message Section Style */
.director-message-section {
  padding: 40px;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  background-color: #f4f5f7;
}

.director-message-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Ensures mobile responsiveness */
}

.director-image {
  flex: 1;
  text-align: center;
  margin-right: 20px;
}

.director-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.director-message-content {
  flex: 2;
}

.director-message-content h2 {
  font-size: 2rem;
  font-weight: 550;
  color: #295bb1;
  margin-bottom: 20px;
}

.director-message-content p {
  font-size: 18px;
  color: #666;
  text-align: justify;
  line-height: 1.5;
  margin-top: 10px;
}

.director-message-content p:last-of-type {
  font-weight: bold;
  margin-top: 10px;
}

/* Responsiveness for tablets */
@media (max-width: 1024px) {
  .director-message-content h2 {
    font-size: 30px;
  }

  .director-message-content p {
    font-size: 16px;
    text-align: justify;
  }

  .director-image img {
    max-width: 300px;
  }
}

/* Responsiveness for mobile devices */
@media (max-width: 768px) {
  .director-message-container {
    flex-direction: column; /* Stacks the image and content */
    text-align: justify;
  }

  .director-image {
    margin: 0 0 20px 0;
  }

  .director-message-content h2 {
    font-size: 28px;
  }

  .director-message-content p {
    font-size: 16px;
  }
}

/* Extra small devices (phones in portrait mode) */
@media (max-width: 480px) {
  .director-message-section {
    padding: 20px;
  }

  .director-message-content h2 {
    font-size: 1.3rem;
  }

  .director-message-content p {
    font-size: 14px;
    /* padding: 10px; */
  }

  .director-image img {
    max-width: 250px;
  }
}


/* 7. Courses Section Styles */
.coursesh {
  background-image: url('./assets/images/jxmufapp.png');
  background-size: cover;
  background-position: center;
  padding: 2rem 1rem;
  /* margin-top: 5px; */
  /* margin-bottom: 5px; */
  border-radius: 5px;
  background-color: #0d0c0c;
  text-align: center;
}

.coursesh h2 {
  font-size: 2rem;
  font-weight: 550;
  color: #295bb1;
  margin-bottom: 1rem;
  /* color: #fc1937; */
}

.admission-button {
  background-color: #fc1937;
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effect for button */
.admission-button:hover {
  background-color: #ff4c4c;
  transform: scale(1.05);
}

.courseh-list {
  display: grid;
  justify-content: space-around;
  /* flex-wrap: wrap; */
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: stretch; /* Ensures all cards stretch to the same height */
}

.courseh-card {
  background-color: #f8f5f5;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  max-width: 380px;
  box-shadow: 0 0 10px rgba(18, 25, 17, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.1s ease;
  flex-grow: 1; /* Ensures equal height */
}

/* Hover effect */
.courseh-card:hover {
  transform: scale(1.05);
  background-color: #e0e0e0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.courseh-card h3 {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.courseh-card ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  flex-grow: 1; /* Ensure the list takes up available vertical space */
}

.courseh-card li {
  margin-bottom: 10px;
}

/* Responsiveness for tablets */
@media (max-width: 1024px) {
  .coursesh h2 {
    font-size: 2rem;
  }

  .admission-button {
    font-size: 1rem;
    padding: 0.8rem;
  }

  .course-card {
    max-width: 280px;
    padding: 1.5rem;
  }

  .course-card h3 {
    font-size: 1.3rem;
  }
}

/* Responsiveness for mobile devices */
@media (max-width: 768px) {
  .coursesh {
    padding: 1.5rem 1rem;
  }

  .coursesh h2 {
    font-size: 1.8rem;
  }

  .admission-button {
    font-size: 1rem;
    padding: 0.6rem 1rem;
  }

  .courseh-list {
    flex-direction: column;
    align-items: center;
  }

  .courseh-card {
    max-width: 90%;
    padding: 1.5rem;
  }

  .courseh-card h3 {
    font-size: 1.2rem;
  }

  .course-card ul {
    padding-left: 15px;
  }
}

/* Extra small devices (phones in portrait mode) */
@media (max-width: 480px) {
  .coursesh h2 {
    font-size: 1.5rem;
  }

  .admission-button {
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
  }

  .courseh-card {
    max-width: 100%;
    padding: 1rem;
  }

  .courseh-card h3 {
    font-size: 1rem;
  }

  .courseh-card ul {
    padding-left: 10px;
  }

  .courseh-card li {
    font-size: 0.9rem;
  }
}


/* 8. Awards and Accomplishments Section */
.awardsaccomplishments-section {
  background-color: #f5f5f5;
  padding: 40px;
  text-align: center;
}

.awardsaccomplishments-title {
  font-size: 2rem;
  font-weight: 550;
  color: #295bb1;
  margin-bottom: 20px;
}

.awardsaccomplishments-subtitle {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 40px;
}

/* Flex container for cards */
.awardsaccomplishments-cards {
  display: flex;
  overflow-x: auto; /* Scroll horizontally if needed */
  white-space: nowrap; 
  gap: 20px; /* Space between cards */
  /* margin-bottom: 20px; */
}

/* Card styling */
.awardaccomplishments-card {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the content stays centered and space is distributed */
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 260px;
  height: 260px; /* Set a fixed height for uniformity */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-right: 20px;
  margin-bottom: 10px;
}

/* Remove the last card margin */
.awardsaccomplishments-cards .awardaccomplishments-card:last-child {
  margin-right: 0;
}

/* Hover effect */
.awardaccomplishments-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.awardaccomplishments-image {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  display: block;
}

/* Heading styling */
.awardaccomplishments-card h3 {
  font-size: 0.8rem;
  color: #295bb1;
}

.awardaccomplishments-card h4 {
  font-size: 0.9rem;
  color: #2c3e50; /* Darker heading */
  margin-bottom: 10px;
}

.awardaccomplishments-card p {
  font-size: 0.88rem;
  color: #555;
  margin-bottom: auto; /* Pushes the paragraph down to the bottom */
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .awardsaccomplishments-title {
    font-size: 2rem;
  }

  .awardsaccomplishments-subtitle {
    font-size: 1rem;
  }

  .awardsaccomplishments-cards {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center cards */
    gap: 15px; /* Adjust gap for smaller screens */
  }

  .awardaccomplishments-card {
    width: 90%; /* Full width on small screens */
    height: auto; /* Auto height */
  }
}

@media (max-width: 480px) {
  .awardsaccomplishments-title {
    font-size: 1.19rem;
  }

  .awardsaccomplishments-subtitle {
    font-size: 14px;
  }

  .awardsaccomplishments-cards {
    gap: 10px; /* Smaller gap for extra small screens */
  }

  .awardaccomplishments-card {
    width: 100%; /* Full width with slight margin */
    height: auto; /* Auto height */
    padding: 15px;
  }

  .awardaccomplishments-image {
    width: 100px;
  }

  .awardaccomplishments-card h4 {
    font-size: 1.1rem;
  }
}


/* 9. Announcements and News Events Setion Style*/
.container {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  gap: 30px;
}

.section {
  width: 45%;
  /* height: auto; */
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.section-title {
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 1.5rem;
  font-weight: 550;
  color: #295bb1;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.item:last-child {
  border-bottom: none; /* Remove bottom border from the last item */
}

.item-title {
  font-size: 18px;
  color: #555;
}

.item-link {
  background-color: #479ffd;
  color: white;
  padding: 5px 15px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.9rem;
}

.item-link:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 20px;
  }

  .section {
    width: 100%;
  }

  .section-title {
    font-size: 1.3rem;
  }

  .item-title {
    font-size: 16px;
  }

  .item-link {
    font-size: 0.8rem;
    padding: 4px 12px;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
    gap: 20px;
  }

  .section {
    width: 100%;
  }

  .section-title {
    font-size: 1.2rem;
  }

  .item-title {
    font-size: 14px;
  }

  .item-link {
    font-size: 0.7rem;
    padding: 3px 10px;
  }
}


/* 10. Testimonials Section Styles */
.testimonials {
  padding: 2rem 2rem;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #ccf0f5;
  text-align: center;
}

.testimonials h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 550;
  color: #295bb1;
}

.testimonials-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.testimonial {
  max-width: 300px;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  background-color: #f5f3eae7;
  box-shadow: 0 5px 10px rgba(35, 4, 4, 0.1);
  text-align: center;
}

.testimonial-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

.testimonial p {
  font-size: 1rem;
  color: #090909;
  margin-bottom: 0.5rem;
}

.testimonial h4 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #484851;
}
@media (max-width: 768px) {
  .testimonials h2  {
    font-size: 1.5rem;
  }
}

/* Container for the whole gallery section */
.gallery-section {
  text-align: center;
  background-color: #fff;
  padding: 40px 0;
}

.gallery-title {
  font-size: 2rem;
  font-weight: 550;
  color: #295bb1;
  margin-bottom: 20px;
}

/* Grid layout for the gallery items */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 0 40px;
}

/* Each item in the gallery */
.gallery-item {
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-item:hover {
  transform: scale(1.05);
}

/* Style for the View Gallery button */
.gallery-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f97316; /* Adjust button color */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.gallery-button:hover {
  background-color: #ff6600; /* Adjust hover effect */
}
@media (max-width: 768px) {
  .gallery-title {
    font-size: 1.5rem;
  }
}

/* 11. Advantages Section Style */
.advantage-section {
  background-color: #e0f7fa; /* Light blue background */
  padding: 40px;
  text-align: center;
}

.advantage-title {
  font-size: 2rem;
  font-weight: 550;
  color: #295bb1; /* Dark blue color for title */
}

.advantage-subtitle {
  font-size: 1.25rem;
  color: #295bb1; /* Gray color for subtitle */
  margin-bottom: 20px;
}

.advantage-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.advantage-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.advantage-card h3 {
  font-size: 1.5rem;
  color: #295bb1;
  margin-bottom: 10px;
}

.advantage-card ul {
  list-style: none;
  padding: 0;
}

.advantage-card ul li {
  margin: 8px 0;
  font-size: 18px;
  color: #37474f;
}

.icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #0288d1;
}
/* Mobile and Smaller Screens (max-width: 768px) */
@media (max-width: 768px) {
  .advantage-titles {
    font-size: 2rem;
  }

  .advantage-subtitle {
    font-size: 1.1rem;
  }

  .advantage-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust grid layout */
  }

  .advantage-card h3 {
    font-size: 1.25rem;
  }

  .advantage-card ul li {
    font-size: 16px;
  }
}

/* Extra Small Devices (max-width: 480px) */
@media (max-width: 480px) {
  .advantage-title {
    font-size: 1.5rem;
  }

  .advantage-subtitle {
    font-size: 14px;
    text-align: justify;
  }

  .advantage-grid {
    grid-template-columns: 1fr; /* Single column on very small screens */
    gap: 15px; /* Reduced gap for smaller screens */
  }

  .advantage-card {
    padding: 15px;
  }

  .advantage-card h3 {
    font-size: 1.1rem;
  }

  .advantage-card ul li {
    font-size: 14px;
  }
}

/* 12. Fee Structure Section Style*/
.fee-structure-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.fee-structure-section h2 {
  font-size: 2rem;
  font-weight: 550;
  color: #295bb1;
  margin-bottom: 30px;
}

.fee-table {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.fee-table thead {
  background-color: #8f8e8e;
  color: #fff;
}

.fee-table th, .fee-table td {
  padding: 15px 20px;
  text-align: left;
  font-size: 18px;
  border-bottom: 1px solid #ddd;
}

.fee-table th {
  font-weight: bold;
}

.fee-table tr:last-child td {
  border-bottom: none;
}

.fee-table tbody tr:hover {
  background-color: #f0f0f0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .fee-table th, .fee-table td {
    font-size: 16px;
    padding: 12px 15px;
  }
  .fee-structure-section h2 {
    font-size: 2rem;
    font-weight: 650;
    color: #295bb1;
  }
}

@media (max-width: 480px) {
  .fee-structure-section h2 {
    font-size: 1.5rem;
    font-weight: 650;
    color: #295bb1;
    margin-bottom: 20px;
  }
  .fee-table {
    width: 100%;
    font-size: 14px;
  }
  .fee-table th, .fee-table td {
    padding: 10px;
  }
}


/* 13. Centers Location Section Style */
.centers-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #fff;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.content {
  max-width: 45%;
  flex-basis: 45%; /* Allow content to shrink and grow */
}

.content h4 {
  color: #f03;
  font-size: 18px;
  margin-bottom: 10px;
}

.content h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.content h1 span {
  color: #ff4081;
}

.content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.content ul li {
  font-size: 18px;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap; /* Stack buttons on smaller screens */
}

.book-visit,
.explore-centers {
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-visit {
  background-color: #fff;
  border: 1px solid #000;
}

.book-visit:hover {
  background-color: #f0f0f0;
}

.explore-centers {
  background-color: #ff4081;
  color: #fff;
}

.explore-centers:hover {
  background-color: #e81f66;
}

.image-container {
  position: relative;
  max-width: 50%;
  flex-basis: 50%; /* Allow image container to shrink and grow */
}

.image-container img {
  width: 100%;
  border-radius: 10px;
}

.play-buttonh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-buttonh button {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 12px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.play-buttonh button:hover {
  background-color: rgba(255, 255, 255, 1);
}

/* Responsive Styles */

/* Tablets and medium-sized screens */
@media (max-width: 1024px) {
  .centers-container {
    padding: 30px;
  }

  .content {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 20px; /* Add margin for spacing */
  }

  .content h1 {
    font-size: 28px;
  }

  .content ul li {
    font-size: 16px;
  }

  .buttons {
    justify-content: center;
  }

  .image-container {
    max-width: 100%;
    flex-basis: 100%;
  }
}

/* Mobile and smaller screens */
@media (max-width: 768px) {
  .centers-container {
    padding: 20px;
  }

  .content h1 {
    font-size: 24px;
  }

  .content ul li {
    font-size: 14px;
  }

  .buttons {
    flex-direction: column; /* Stack buttons vertically */
    gap: 15px;
  }

  .book-visit,
  .explore-centers {
    width: 100%; /* Full width buttons for mobile */
    text-align: center;
  }

  .image-container {
    margin-top: 20px; /* Add margin between content and image */
  }

  .image-container img {
    margin-top: -20px;
    border-radius: 8px;
  }
}

/* Extra small devices (phones) */
@media (max-width: 480px) {
  .centers-container {
    padding: 15px;
  }

  .content h1 {
    font-size: 19px;
  }

  .content ul li {
    font-size: 14px;
    text-align: justify;
  }
  .book-visit{
    width: 40%; /* Full width buttons for mobile */
    text-align: center;
  }
  .explore-centers {
    width: 62%; /* Full width buttons for mobile */
    text-align: center;
  }
  .buttons {
    gap: 10px;
  }
  .play-buttonh button {
    padding: 10px;
    font-size: 20px;
  }
}

/* Footer Section Styles */
.footer {
  background-color: #eae3e3;
  padding: 1rem;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
}

.footer-contact p {
  color: #020202;
  font-size: 1rem;
  margin:  0;
  line-height: 1.5;
}

.footer-contact a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}
.footer-contact a:hover {
  text-decoration: none;
}

.footer-links {
  display: flex;
  gap: 5px;
  font-size: 1rem;
}

.footer-links a {
  color: #333;
  text-decoration: none;
  font-weight: 600;
}

.footer-links a:hover {
  text-decoration: none;
}

.footer-designed {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0;
}

.footer-designed p {
  color: #020202;
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1;  
}

.footer-logo {
  width: 50px;
  vertical-align: middle;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .footer {
    padding: 1.5rem 1rem;
  }

  .footer-contact p,
  .footer-links a {
    font-size: 0.9rem;
  }

  .footer-logo {
    width: 40px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 1rem;
  }

  .footer-contact p {
    font-size: 0.85rem;
  }

  .footer-designed p {
    font-size: 0.85rem;
  }

  .footer-logo {
    width: 35px;
  }
}
