/* Google Fonts Import */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* Base Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f5f7fa;
  color: #333;
  line-height: 1.6;
}

/* Main Container */
.results-container {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  margin: 1rem auto;
  gap: 1rem;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .results-container {
    flex-direction: row;
    align-items: flex-start;
  }
}

@media (min-width: 1024px) {
  .results-container {
    padding: 0 2rem;
  }
}

/* Sidebar Styles */
.sidebar {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  order: 1;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .sidebar {
    width: 280px;
    flex-shrink: 0;
    order: 0;
    margin-bottom: 0;
  }
}

.sidebar-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.sidebar-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, #6366f1, #8b5cf6);
  border-radius: 3px;
}

.filter-group {
  margin-bottom: 1.5rem;
}

.filter-label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #4a5568;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:after {
  content: "▼";
  font-size: 0.8rem;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6366f1;
  pointer-events: none;
}

.filter-select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 0.95rem;
  color: #4a5568;
  appearance: none;
  transition: all 0.2s ease;
  cursor: pointer;
}

.filter-select:focus {
  outline: none;
  border-color: #6366f1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.2);
}

/* Results Section */
.results-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.batch-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 1rem;
  transition: all 0.3s ease;
}

.batch-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.batch-heading:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, #6366f1, #8b5cf6);
  border-radius: 3px;
}

.year-section {
  margin-bottom: 2rem;
}

.year-section:last-child {
  margin-bottom: 0;
}

.year-badge {
  display: inline-block;
  background: linear-gradient(90deg, #6366f1, #8b5cf6);
  color: white;
  font-weight: 600;
  padding: 0.4rem 1rem;
  border-radius: 30px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

@media (max-width: 1200px) {
  .results-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .results-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .results-grid {
    grid-template-columns: 1fr;
  }
}

.result-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  width: 90%;
  height: 90%;
  padding: 1rem;
}

.result-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.result-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
}

.img-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1.5rem;
  border: 4px solid #e2e8f0;
  transition: all 0.3s ease;
}

.result-card:hover .img-container {
  border-color: #6366f1;
  transform: scale(1.05);
}

.result-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(135deg, #6366f1, #8b5cf6);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.result-score {
  background: linear-gradient(90deg, #6366f1, #8b5cf6);
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  display: inline-block;
}

.result-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #2d3748;
  margin: 0;
}

.no-results {
  text-align: center;
  padding: 2rem;
  color: #718096;
}

.no-results h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #4a5568;
}

/* Mobile Toggle Button */
.mobile-filter-toggler {
  display: none;
}

@media (max-width: 767px) {
  .mobile-filter-toggler {
    display: block;
    position: fixed;
    top: 80px;
    right: 10px;
    z-index: 1000;
    background: #6366f1;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.3);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all 0.3s ease;
    background: rgb(225, 222, 222);
    padding: 1.5rem;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.08);
  }

  .sidebar.active {
    top: 7%;
  }
}