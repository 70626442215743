/* Base styles for the contact section */
.contact-section {
  background-color: #e0f7fa; /* Light blue background */
  padding: 40px 20px; /* Added padding on smaller screens */
  text-align: center;
}

/* Title styling */
.contact-title {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1; /* Dark blue color for title */
  margin-bottom: 20px; /* Spacing below title */
}

/* Subtitle styling */
.contact-subtitle {
  font-size: 1.25rem;
  color: #37474f; /* Gray color for subtitle */
  margin-bottom: 20px;
}

/* Contact grid layout */
.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid layout */
  gap: 20px; /* Space between grid items */
}

/* Contact card styling */
.contact-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.contact-card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
}

.contact-card h4 {
  font-size: 1.5rem;
  color: #042c6f;
  margin-bottom: 10px;
}

.contact-card p {
  font-size: 1rem;
  color: #37474f;
}

/* Icon styling */
.icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #0288d1;
}

/* Responsive styles */
@media (max-width: 768px) {
  .contact-title {
    font-size: 1.75rem; /* Slightly smaller font size */
  }

  .contact-subtitle {
    font-size: 1.15rem; /* Adjust subtitle size */
  }

  .contact-card {
    padding: 15px; /* Less padding for smaller screens */
  }

  .contact-card h4 {
    font-size: 1.35rem;
  }

  .contact-card p {
    font-size: 0.95rem;
  }

  .icon {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .contact-title {
    font-size: 1.5rem; /* Further reduce the title size */
  }

  .contact-subtitle {
    font-size: 1rem; /* Smaller subtitle for mobile */
  }

  .contact-grid {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }

  .contact-card {
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .contact-card h4 {
    font-size: 1.2rem;
  }

  .contact-card p {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1.6rem;
  }
}
