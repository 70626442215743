.all-courses-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
}

.heading{
  text-align: center;
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
}
/* Course Card */
.course-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 900px; /* Increased card width */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.course-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.course-icon {
  font-size: 40px;
}

.course-header h3 {
  font-size: 22px;
  flex: 1;
  word-wrap: break-word; /* Ensures long titles wrap properly */
}

/* Content Row for 'For', 'Scholarship', and 'DownloadLink' */
.course-content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Wrap items on small screens */
}

.course-content-row p {
  margin: 5px 10px 5px 0;
}

.course-content-row .download-link {
  flex-grow: 1;
  text-align: right;
  margin-left: auto;
  text-decoration: none !important; /* Add !important to enforce the rule */
  font-weight: bold;
  color: #007bff;
}

.course-content-row .download-link:hover {
  text-decoration: none !important; /* Enforce it on hover as well */
}

/* Course Details */
.course-details {
  padding: 10px 0;
  font-size: 14px;
}

/* Course Footer */
.course-footer {
  background-color: #f9f9f9;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  margin-top: 15px;
}

.course-footer p {
  margin: 5px 0;
}

.course-actions {
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  margin-top: 10px;
}

.action-button {
  background-color: #3d98f9;
  color: white;
  border: none;
  width: 25%;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .course-card {
    padding: 15px;
  }

  .course-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .course-content-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .course-content-row .download-link {
    text-align: left;
    margin: 5px 0 0 0;
  }

  .course-actions {
    flex-direction: column;
    gap: 10px;
  }

  .action-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .course-header h3 {
    font-size: 18px;
  }

  .course-icon {
    font-size: 30px;
  }

  .course-card {
    max-width: 100%;
  }
}
