
.results-page-container {
    padding: 20px;
    text-align: center;
  }
  
  .results-title {
    font-size: 2.5rem;
    color: #0072ce;
    margin-bottom: 30px;
  }
  
  .student-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    justify-items: center;
  }
  
  .student-card {
    background-color: #fff;
    border-radius: 10px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .student-card:hover {
    transform: translateY(-10px);
  }
  
  .student-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
    border: 3px solid #f0f0f0;
  }
  
  .student-rank {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0072ce;
  }
  
  .student-name {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 5px 0;
  }
  
  .student-details {
    font-size: 1rem;
    color: #666;
  }
  
  /* Media Query for max-width 1024px (larger tablets, small laptops) */
@media (max-width: 1024px) {
    .student-grid {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 20px;
    }
  
    .student-card {
      padding: 20px;
    }
  
    .student-photo {
      width: 140px;
      height: 140px;
    }
  
    .student-rank {
      font-size: 1.5rem;
    }
  
    .student-name {
      font-size: 1.2rem;
    }
  
    .results-title {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 768px) {
    .student-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 15px;
    }
  
    .student-card {
      padding: 18px;
    }
  
    .student-photo {
      width: 130px;
      height: 130px;
    }
  
    .student-rank {
      font-size: 1.4rem;
    }
  
    .student-name {
      font-size: 1.1rem;
    }
  
    .results-title {
      font-size: 2.2rem;
    }
  }

  @media (max-width: 600px) {
    .student-card {
      padding: 15px;
    }
  
    .student-photo {
      width: 120px;
      height: 120px;
    }
  
    .student-rank {
      font-size: 1.3rem;
    }
  
    .student-name {
      font-size: 1rem;
    }
  
    .results-title {
      font-size: 2rem;
    }
  }
  
@media (max-width: 450px) {
    .student-grid {
      grid-template-columns: repeat(2, 1fr); /* This will display 2 cards side by side */
      gap: 10px; /* Adjust gap between the cards */
    }

    .student-card {
      width: 150px;
      padding: 10px;
    }
  
    .student-photo {
      width: 100px;
      height: 100px;
    }
  
    .student-rank {
      font-size: 1.1rem;
    }
  
    .student-name {
      font-size: 0.9rem;
    }
  
    .results-title {
      font-size: 1.8rem;
    }
  }