/* .results-container {
    padding: 20px;
    background-color: #f4faff;
    text-align: center;
    display: flex;
  } */
  /* .results-container h2{
    font-size: 2rem;
    font-weight: 550;
    color: #295bb1;
  } */
  .results-title {
    padding: 10px 0 0 0;
    text-align: center;
    font-size: 2rem;
    font-weight: 550;
    color: #295bb1;
  }
  
  .results-cards {
    display: flex;
    justify-content: space-around;
  }
  
  .result-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    text-align: center;
  }
  
  .result-header {
    font-size: 17px;
    background-color: #fd5776;
    color: white;
    padding: 10px;
    border-radius: 10px 10px 0 0;
  }
  
  .result-students {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
  }
  
  .student {
    text-align: center;
  }
  .student h4 {
    font-size: 18px;
  }
  .student h5 {
    padding: 15px;
    font-size: 15px;
  }
  .student p {
    padding: 2px;
    font-size: 14px;
  }
  
  .student-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  .see-results {
    display: block;
    /* margin-top: 20px; */
    color: #03a9f4;
    font-weight: bold;
    text-decoration: none;
  }
  
  .see-results:hover {
    text-decoration: none;
    color: #0d47a1;
  }
  
  /* Responsive Design */
  /* Medium screens (max-width: 1024px) */
  @media (max-width: 1024px) {
    .result-card {
      width: calc(45% - 20px); /* Adjust card width for medium screens */
    }
  
    .result-header {
      font-size: 1.2rem;
    }
  
    .student h4 {
      font-size: 1.1rem;
    }
  
    .student p {
      font-size: 0.85rem;
    }
  
    .student-img {
      width: 75px;
      height: 75px;
    }
  }
  
  /* Small screens (max-width: 768px) */
  @media (max-width: 768px) {
    .result-card {
      width: calc(100% - 40px); /* Cards take full width on smaller screens */
    }
  
    .result-header {
      font-size: 1.1rem;
    }
  
    .student h4 {
      font-size: 1rem;
    }
  
    .student h5 {
      font-size: 0.9rem;
    }
  
    .student p {
      font-size: 0.8rem;
    }
  
    .student-img {
      width: 70px;
      height: 70px;
    }
  }
  
  /* Extra small screens (max-width: 450px) */
  @media (max-width: 450px) {
    .results-title {
      font-size: 1.8rem;
    }
  
    .result-card {
      padding: 15px;
    }
  
    .student-img {
      width: 60px;
      height: 60px;
    }
  
    .student h4 {
      font-size: 0.9rem;
    }
  
    .student h5 {
      font-size: 0.85rem;
    }
  
    .student p {
      font-size: 0.75rem;
    }
  }
  