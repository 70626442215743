.terms-container {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 2rem auto;
  font-family: 'Arial', sans-serif;
}

.terms-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.terms-subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #222;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.terms-container p {
  font-size: 1rem;
  color: #555;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.terms-list {
  list-style-type: disc; /* Standard bullet points */
  padding-left: 1.5rem; /* Indentation for list */
}

.terms-list li {
  font-size: 1rem;
  color: #555;
  padding: 0.5rem 0;
  line-height: 1.2;
  border-bottom: none; /* Removes underline */
}

.terms-footer {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-top: 2rem;
  text-align: center;
}

@media (max-width: 768px) {
  .terms-container {
    padding: 1rem;
    margin: 1rem;
  }

  .terms-title {
    font-size: 1.5rem;
  }

  .terms-subtitle {
    font-size: 1.2rem;
  }

  .terms-list {
    padding-left: 1rem;
  }

  .terms-list li {
    font-size: 0.95rem;
    padding: 0.4rem 0;
  }

  .terms-footer {
    font-size: 0.9rem;
  }
}
